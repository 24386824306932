import { Box } from '@mui/material';
import { COLORS } from '../constants';

type CustomLinearProgressProps = {
  progress: number;
  colorTrack?: string;
  colorBar?: string;
};

export const CustomLinearProgress = ({
  progress,
  colorBar = COLORS.RED,
  colorTrack = COLORS.GRAY_LIGHT,
}: CustomLinearProgressProps) => {
  return (
    <>
      <Box
        sx={{
          background: colorTrack,
          width: '100%',
          height: '10px',
          borderRadius: '6px',
          padding: 0,
        }}
      >
        <Box
          sx={{
            height: '10px',
            width: `${progress}%`,
            borderRadius: '6px',
            background: colorBar,
            // filter: `drop-shadow(0px 1.2px 3px ${colorBar})`,
          }}
        />
      </Box>
    </>
  );
};
