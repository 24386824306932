import { lazy, useEffect, useRef, useState } from 'react';

// mui components
import {
  Box,
  // useTheme
} from '@mui/material';

import { CustomSelect, Heading, ModuleList } from './components';
import ConfettiExplosion from 'react-confetti-explosion';

const AnnounceCertificate = lazy(() =>
  lazyRetry(() => import('./components/AnnounceCertificate')),
);

// hooks and utils
import { useUserRoutes } from '../../hooks';
import { useMetrics } from '../../hooks/useMetrics';
import { useConfig } from '../../hooks/useConfig';

// icons
import { useModules } from '../../hooks/useModules';
import { useTranslation } from 'react-i18next';
import { ContentProgressModal } from './components/ContentProgressModal';
import { useSearchParams } from 'react-router-dom';
import { UnderConstruction } from './components/UnderConstruction';
import { QueryParamsStrings } from '../../constants/queryParamsStrings';
import { lazyRetry } from '../../utils/general';
import toast from 'react-hot-toast';
import { CustomLinearProgress } from '@/pages/ListContentPage/components/CustomLinearProgress';
import { COLORS } from '@/pages/ListContentPage/constants';
import { getColorProgressCourse } from '@/pages/ListContentPage/utils';

export const ContentPageNewDesign = () => {
  const [searchParamsFromReactRouter, setSearchParams] = useSearchParams();
  const showProgressModal =
    searchParamsFromReactRouter.get('show_theoretical_modal')?.toLowerCase() ===
    'true';
  //states
  const [activeCourse, setActiveCourse] = useState<number>(0);

  const [showUnderConstruction, setShowUnderConstruction] = useState(false);
  const [previousRoute, setPreviousRoute] = useState(0);
  //hooks
  const { t } = useTranslation();
  const metrics = useMetrics();

  const config = useConfig();
  const { getRoutes, routes, routeFormater, getCurrentRoute, getOneRouteInfo } =
    useUserRoutes();
  const modules = useModules(true);

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  const showCerfiticateSection = () => {
    return (
      !config.isMeidei &&
      config.showCertificates &&
      !config.isSimple &&
      activeCourse !== 0
    );
  };

  useEffect(() => {
    const currentRouteId = getCurrentRoute().id;
    if (currentRouteId !== previousRoute) {
      if (currentRouteId && activeCourse === 0) {
        modules.getModules(Number(currentRouteId));
        setActiveCourse(Number(currentRouteId));
      } else {
        modules.getModules(!!activeCourse ? activeCourse : currentRouteId);
      }
      setPreviousRoute(currentRouteId);
    }
  }, [getCurrentRoute().id, activeCourse, routes]);

  useEffect(() => {
    const routeInfo = getOneRouteInfo(getCurrentRoute().id);
    const canShowUnderConstruction =
      routeInfo.progressPercentage === 100 && routeInfo.finished === false;
    if (canShowUnderConstruction) {
      setShowUnderConstruction(true);
    }
  }, [getCurrentRoute().id]);

  useEffect(() => {
    const currentRouteId = getCurrentRoute().id;
    if (Boolean(currentRouteId)) {
      if (
        searchParamsFromReactRouter.get(QueryParamsStrings.SELECTED_ROUTE) ===
        null
      ) {
        setSearchParams({
          ...searchParamsFromReactRouter,
          selected_route: String(currentRouteId),
        });
      }
    }
  }, [routes, getCurrentRoute().id]);

  const [showConfetti, setShowConfetti] = useState(false);
  const firstRender = useRef(true);
  useEffect(() => {
    if (searchParamsFromReactRouter.get('party')?.toLowerCase() === 'true') {
      if (!firstRender.current) return;
      setShowConfetti(true);
      toast.success(
        `${t('learningPathScreen.congratulations')}. ${t(
          'contentScreen.upToDate',
        )}`,
        {
          icon: <>🎉</>,
        },
      );
      firstRender.current = false;
    }
  }, [searchParamsFromReactRouter.get('party')]);

  function countHighProgressModules(modules: any) {
    return modules.filter((module: any) => {
      // Si el valor es null, se trata como 0
      const progress =
        module.progress_percentage === null ? 0 : module.progress_percentage;
      return progress >= 90;
    }).length;
  }

  return (
    <>
      {showConfetti && (
        <>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <ConfettiExplosion />
            </div>
            <div>
              <ConfettiExplosion />
            </div>
          </div>
        </>
      )}

      {showProgressModal && <ContentProgressModal />}
      {showUnderConstruction && (
        <UnderConstruction
          setOpen={setShowUnderConstruction}
          open={showUnderConstruction}
        />
      )}
      <Box
        maxWidth={{
          sm: '100%',
          md: '70%',
        }}
        marginX={'auto'}
      >
        <Heading />
        {config.isSimple ? (
          <></>
        ) : (
          <Box marginY={'1rem'}>
            <CustomSelect
              label={
                config.isSimple
                  ? 'Mis contenidos'
                  : config.isMeidei
                  ? t('general.courseMeidei')
                  : config.isCustom
                  ? config.isCustom
                  : t('general.course')
              }
              onChange={(id: any) => {
                setActiveCourse(id);
                modules.getModules(id);
                setSearchParams({
                  ...searchParamsFromReactRouter,
                  selected_route: String(id),
                });
                metrics.track({
                  action: 'click-select',
                  extra_data: {
                    button: metrics.MetricsKeys.SELECT_MODULE,
                    path: '/content',
                  },
                });
              }}
              options={routeFormater(routes)}
              currentValue={
                Boolean(activeCourse) ? activeCourse : getCurrentRoute().id
              }
            />
          </Box>
        )}

        {modules.isLoading ? (
          ''
        ) : (
          <Box
            component="div"
            marginBottom={'1rem'}
            marginTop={'2rem'}
            width={'100%'}
          >
            <h4
              style={{
                fontSize: '14px',
                marginBottom: '.5rem',
              }}
            >
              {routes.find(
                (id) =>
                  id.route.pk ===
                  Number(searchParamsFromReactRouter.get('selected_route')),
              )?.route.name ?? ''}
            </h4>
            <CustomLinearProgress
              progress={
                routes.find(
                  (id) =>
                    id.route.pk ===
                    Number(searchParamsFromReactRouter.get('selected_route')),
                )?.progress_percentage ?? 0
              }
              colorTrack={COLORS.GRAY_EXTRA_LIGHT}
              colorBar={getColorProgressCourse(
                Number(
                  routes.find(
                    (id) =>
                      id.route.pk ===
                      Number(searchParamsFromReactRouter.get('selected_route')),
                  )?.progress_percentage ?? 0,
                ),
              )}
            />
            <div
              style={{
                fontSize: '12px',
                marginTop: '.8rem',
              }}
            >
              {countHighProgressModules(modules.data)}/{modules.data.length}{' '}
              modulos del curso
            </div>
          </Box>
        )}
        <ModuleList
          isLoading={modules.isLoading}
          modules={modules.listModulesFormatter(modules.data)}
        />
        {showCerfiticateSection() && (
          <AnnounceCertificate
            completedModules={modules.getCompletedModulesCount()}
            totalModules={modules.metadata?.count}
            courseTitle={
              getOneRouteInfo(
                activeCourse === 0 ? getCurrentRoute()?.id : activeCourse,
              )?.name ?? ''
            }
          />
        )}
      </Box>
    </>
  );
};

export default ContentPageNewDesign;
