import { COLORS } from "@/pages/ListContentPage/constants";


const getColorProgressCourse = (progress: number) => {
  if (progress === 100) return COLORS.GREEN;
  if (progress < 95) return COLORS.RED;
  if (progress > 95) return COLORS.GREEN;
  return COLORS.GRAY;
};

export { getColorProgressCourse };
